<!--
 * @Date: 2021-04-29 20:52:23
 * @LastEditors: frank
 * @LastEditTime: 2021-05-18 20:25:39
 * @FilePath: /shop_frontend/src/views/orderReturn/returnDetail.vue
 * @Description: Description
-->
<template>
  <section class="page returnDetail">
    <a-card title="退货退款申请">
      <div class="flex-between">
        <div>
          <div>{{ showStatus(info.status) }}</div>
          <!-- <p>您已经同意退款，请等待买家处理。</p> -->
        </div>
        <div>
          <div v-if="info.status == 0">
            <a-button type="primary" style="margin-left: 10px" @click="accept"
              >同意退货</a-button
            >
            <a-button type="" style="margin-left: 10px" @click="refuse"
              >拒绝退货</a-button
            >
          </div>
          <div v-if="info.status == 2">
            <a-button
              type="primary"
              @click="acceptConfirmModal"
              style="margin-left: 10px"
              >确认收货并退款</a-button
            >
            <a-button
              type=""
              style="margin-left: 10px"
              @click="refuseConfirmModal"
              >拒绝确认收货</a-button
            >
          </div>
        </div>
      </div>
    </a-card>
    <a-card title="退货退款申请详情" style="margin-top: 20px">
      <div class="goods__table">
        <table>
          <tr>
            <td>订单号</td>
            <td style="color: #549ff8">{{ info.sales_order_item.number }}</td>
            <td>退款编号</td>
            <td>{{ info.number }}</td>
          </tr>
          <tr>
            <td>退货商品</td>
            <td colspan="3">
              <div
                class="flex"
                :key="item.id"
                v-for="item in info.sales_order_item.sales_goods_items"
              >
                <img :src="item.spec_picture_url" alt=""   style="
                  display: inline-block;
                  width: 80px;
                  height: 80px;
                  margin-right: 10px;
                "/>
                <div>
                  <p>{{ item.goods_name }}</p>
                  <p>{{ item.spec_name }}</p>
                  <p>￥{{ item.member_price }}</p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>下单人信息</td>
            <td colspan="3">
              <div style="color: #549ff8">
                {{ info.sales_order_item.member_name }}
                {{ info.sales_order_item.member_phone }}
              </div>
            </td>
          </tr>
          <!-- <tr>
            <td>退款类型</td>
            <td colspan="3"></td>
          </tr> -->
          <tr>
            <td>退款状态</td>
            <td colspan="3">{{ showStatus(info.status) }}</td>
          </tr>
          <tr>
            <td>退款金额</td>
            <td colspan="3">
              <div>
                退商品金额：
                <a-input
                  disabled
                  v-model="info.refund_amount"
                  class="w120"
                ></a-input>
                元，扣运费金额：
                <a-input
                  disabled
                  v-model="info.freight_amount"
                  class="w120"
                ></a-input
                >元。
                <!-- 可退最大运费{{ info.freight_amount }}元。 -->
              </div>
              <div>
                总退款金额：￥{{ info.total_amount }} = 退商品金额：￥{{
                  info.refund_amount
                }}
                + 扣运费金额：￥{{ info.freight_amount }}
              </div>
            </td>
          </tr>
          <tr>
            <td>退款/退货原因</td>
            <td colspan="3">{{ showReson(info.refund_reason) }}</td>
          </tr>
          <tr>
            <td>说明</td>
            <td colspan="3">{{ info.refund_description }}</td>
          </tr>
          <tr>
            <td>退款图片</td>
            <td colspan="3">
              <img :src="pic" alt="" v-for="pic in info.pictures" :key="pic"  style="
                  display: inline-block;
                  width: 80px;
                  height: 80px;
                
                " />
            </td>
          </tr>
          <tr>
            <td>申请时间</td>
            <td colspan="3">{{ info.create_time }}</td>
          </tr>
        </table>
      </div>
    </a-card>

    <a-card style="margin-top: 20px" title="协商记录">
      <ul class="record">
        <li v-for="(el, index) in info.refund_records" :key="index">
          <div v-if="el.type == 'business'">
            <p class="title">
              <span>商家</span>
             <span style="margin-left: 10px;">{{el.handling_time}}</span>
              <!-- <span>商家同意</span> -->
            </p>
            <p v-if="el.total_amount"> 退款总金额：{{el.total_amount}}</p>
            <p v-if="el.freight_amount"> 扣运费金额：{{el.freight_amount}}</p>
            <p v-if="el.refund_amount"> 实退金额：{{el.refund_amount}}</p>
            <p v-if="el.handling_description"> 处理说明：{{el.handling_description}}</p>
            <p v-if="el.receiving_time"> 确认收货时间：{{el.receiving_time}}</p>
            <p v-if="el.receiving_description"> 确认收货说明：{{el.receiving_description}}</p>
        
          </div>
          <div>
            <p class="title" v-if="el.type == 'buyer'">
              <span>买家</span>
              <span style="margin-left: 10px;">{{el.create_time}}</span>
              <!-- <span>商家同意</span> -->
            </p>
            <p v-if="el.refund_reason"> 退款原因：{{el.refund_reason}}</p>
            <p v-if="el.refund_description"> 退款说明：{{el.refund_description}}</p>
            
          </div>
        </li>
      </ul>
    </a-card>
    <a-modal v-model="visible" :title="title" @ok="handleOk">
      <div>
        <a-form-model
          :model="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-form-model-item label="扣运费金额" v-if="title === '同意退货'">
            <a-input v-model="form.freight_amount" type="number" />
          </a-form-model-item>
          <a-form-model-item label="处理说明">
            <a-input v-model="form.description" type="textarea" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </section>
</template>

<script>
export default {
  name: "returnDetail",
  data() {
    return {
      visible: false,
      title: "",
      form: {
        description: "",
        freight_amount: "",
      },
      info: {},
      statusList: [
        {
          value: 0,
          name: "退款申请等待商家确认",
        },
        {
          value: 1,
          name: "商家拒绝退款申请",
        },
        {
          value: 2,
          name: "商家同意退款, 等待买家退货",
        },
        {
          value: 3,
          name: "商家未收货, 拒绝退款",
        },
        {
          value: 4,
          name: "退款撤销",
        },
        {
          value: 5,
          name: "退款成功",
        },
        {
          value: 6,
          name: "系统处理中",
        },
      ],
    };
  },
  components: {},
  computed: {},
  watch: {},
  created() {
    this.getDetail();
  },
  mounted() {},
  methods: {
    handleOk() {
      
      if (this.title == "同意退货") {
        if (this.form.freight_amount === '') {
          this.$message.warn('请输入扣运费金额')
          return 
        }
        this.$axios
          .post(`/refund_orders/${this.$route.query.id}/accept/`, this.form)
          .then((res) => {
            this.$message.success("操作成功");
            this.getDetail();
            this.visible = false;
          });
      }
      if (this.title == "拒绝退货") {
        this.$axios
          .post(`/refund_orders/${this.$route.query.id}/refuse/`, this.form)
          .then((res) => {
            this.$message.success("操作成功");
            this.getDetail();
            this.visible = false;
          });
      }
      if (this.title == "确认收货并退款") {
        this.acceptConfirm();
      }
      if (this.title == "拒绝确认收货") {
        this.refuseConfirm();
      }
    },
    accept() {
      this.visible = true;
      this.title = "同意退货";
      this.form = {
        description: "",
        freight_amount: "",
      };
    },
    refuse() {
      this.visible = true;
      this.title = "拒绝退货";
      this.form = {
        description: "",
        freight_amount: "",
      };
    },
    acceptConfirmModal() {
      this.visible = true;
      this.title = "确认收货并退款";
      this.form = {
        description: "",
        freight_amount: "",
      };
    },
    acceptConfirm() {
      this.$axios
        .post(
          `/refund_orders/${this.$route.query.id}/accept_confirm/`,
          this.form
        )
        .then((res) => {
          this.$message.success("操作成功");
          this.getDetail();
          this.visible = false
        });
    },
    refuseConfirmModal() {
      this.visible = true;
      this.title = "拒绝确认收货";
      this.form = {
        description: "",
        freight_amount: "",
      };
    },
    refuseConfirm() {
      this.$axios
        .post(
          `/refund_orders/${this.$route.query.id}/refuse_confirm/`,
          this.form
        )
        .then((res) => {
          this.$message.success("操作成功");
          this.getDetail();
           this.visible = false
        });
    },
    showReson(str) {
      return {
        0: "协商一致退款",
        1: "拍错/多拍/不想要",
        2: "其他",
        3: "订单取消",
      }[str];
    },
    showStatus(str) {
      let index = this.statusList.findIndex((el) => el.value == str);
      if (index > -1) {
        return this.statusList[index].name;
      }
      return "";
    },
    getDetail() {
      this.$axios.get(`/refund_orders/${this.$route.query.id}/`).then((res) => {
        this.info = res;
      });
    },
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  flex-flow: row nowrap;
}
.flex-between {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.goods__table {
  table {
    width: 100%;
    td {
      border: 1px solid #ddd;
      height: 40px;
      padding: 4px 10px;
    }
  }
}
.record {
  > li {
    border-bottom: 1px solid #000;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
.title {
  border-bottom: 1px dotted #ddd;
  height: 40px;
}
</style>
